import gql from 'graphql-tag';

export const userFields = `
  id
  name
  avatar
  countryCode
  mobile
  email
  inviteCode
  vipLevel
  balance
  unreadMessageCount
  signupDays
  alreadySignupToday
  withdrawPoint
  effectiveWithdrawPoint
  requiredWithdrawPoint
  requiredWithdrawPointFix
  totalRechargeAmount
  totalRechargeOrders
  totalApplyWithdrawAmount
  totalApplyWithdrawOrders
  totalSuccessWithdrawAmount
  totalSuccessWithdrawOrders
  vipBonusStatuses
  totalPromotionBonus
  totalAppLoginBonus
  unreadAppLoginBonusMessageId
`;

const getUserInfo = gql`
query getUserInfo {
  getUserInfo {
    ${userFields}

    team {
      id
      totalSize
      level1Size
      level2Size
      level3Size

      level1FirstRechargeUsers
      level2FirstRechargeUsers
      level3FirstRechargeUsers

      level1FirstRechargeAmount
      level2FirstRechargeAmount
      level3FirstRechargeAmount

      level1RechargeUsers
      level2RechargeUsers
      level3RechargeUsers

      level1RechargeAmount
      level2RechargeAmount
      level3RechargeAmount

      level1BetAmount
      level2BetAmount
      level3BetAmount

      totalRechargeAmount
      totalFirstRechargeAmount
      totalBetAmount
    }

    customerServices {
      id
      name
      avatar
      linkType
      link
    }

    pixWallet {
      id
      acctType
      acctNo
      acctName
      certId
      contactPhone
      contactEmail
    }
  }
}
`;

const refreshUserInfo = gql`
  query getUserInfo {
    getUserInfo {
      id
      vipLevel
      balance
      unreadMessageCount
      signupDays
      alreadySignupToday
      withdrawPoint
      effectiveWithdrawPoint
      requiredWithdrawPoint
      requiredWithdrawPointFix
      totalRechargeAmount
      totalRechargeOrders
      totalApplyWithdrawAmount
      totalApplyWithdrawOrders
      totalSuccessWithdrawAmount
      totalSuccessWithdrawOrders
      totalPromotionBonus
      totalAppLoginBonus
      unreadAppLoginBonusMessageId
    }
  }
`;

const updateAvatar = gql`
  mutation updateAvatar($avatar: String!) {
    updateAvatar(avatar: $avatar) {
      id
      avatar
    }
  }
`;

const updatePassword = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      id
    }
  }
`;

const updateFundPassword = gql`
  mutation updateFundPassword($password: String!, $identifyCode: String) {
    updateFundPassword(password: $password, identifyCode: $identifyCode) {
      ${userFields}
    }
  }
`;

const updateBankcard = gql`
  mutation updateBankcard($attrs: BankcardParams!) {
    updateBankcard(attrs: $attrs) {
      id
      bankName
      bankCode
      acctNo
      acctName
      contactPhone
      contactEmail
    }
  }
`;

const updatePixWallet = gql`
  mutation updatePixWallet($attrs: PixWalletParams!) {
    updatePixWallet(attrs: $attrs) {
      id
      acctType
      acctNo
      acctName
      certId
      contactPhone
      contactEmail
    }
  }
`;

const updateWallet = gql`
  mutation updateWallet($attrs: UpdateWalletParams!) {
    updateWallet(attrs: $attrs) {
      id
      trcAddress
      auditStatus
    }
  }
`;

const readSiteMessage = gql`
  mutation readSiteMessage($id: Int!) {
    readSiteMessage(id: $id) {
      id
      read
    }
  }
`;

const readAllSiteMessages = gql`
  mutation readAllSiteMessages {
    readAllSiteMessages
  }
`;

const dailySignup = gql`
  mutation dailySignup {
    dailySignup {
      id
      balance
      signupDays
      alreadySignupToday
    }
  }
`;

const teamStatsFields = `{
  level
  teamSize
  totalBetAmount
  totalWithdrawPoint
  firstRechargeUsers
  firstRechargeAmount
  totalRechargeAmount
  totalRechargeUsers
}`;

const getTeamStats = gql`
  query getTeamStats($beginDate: String, $endDate: String) {
    getTeamStats(beginDate: $beginDate, endDate: $endDate) {
      total ${teamStatsFields}
      level1 ${teamStatsFields}
      level2 ${teamStatsFields}
      level3 ${teamStatsFields}
    }
  }
`;

const listSpecialPromotionRewards = gql`
  query listSpecialPromotionRewards {
    listSpecialPromotionRewards {
      totalInvitedUsers
      rewards {
        invitedUsers
        clearStatus
        requiredWithdrawPoint
        bonusMagnification
      }
    }
  }
`;

const receiveVipBonus = gql`
  mutation receiveVipBonus($level: Int!) {
    receiveVipBonus(level: $level) {
      id
      balance
      vipBonusStatuses
    }
  }
`;

const receiveSpecialPromotionReward = gql`
  mutation receiveSpecialPromotionReward($invitedUsers: Int!) {
    receiveSpecialPromotionReward(invitedUsers: $invitedUsers) {
      id
      balance
    }
  }
`;

const getTodayInviteData = gql`
  query getTodayInviteData {
    getTodayInviteData {
      id
      invitedUsers
      firstRechargeUsers
      generalPromotionBonus
      specialPromotionBonus
      promotionBonus
      commissionAmount
    }
  }
`;

const fetchWithdrawParams = gql`
  query fetchWithdrawParams {
    fetchWithdrawParams {
      id
      balance
      effectiveWithdrawPoint
      requiredWithdrawPoint
      requiredWithdrawPointFix
      withdrawTaxRate
      minWithdrawAmount
      maxWithdrawAmount
    }
  }
`;

const listTreasurePromotionRewardSummary = gql`
  query listTreasurePromotionRewardSummary {
    listTreasurePromotionRewardSummary {
      activityId
      effectiveInvites
      claimedInvites
      availableInvites
      unavailableInvites
      totalReward
      claimedReward
      availableReward
      unavailableReward
    }
  }
`;

const listGeneralPromotionDailyRewards = gql`
  query listGeneralPromotionDailyRewards {
    listGeneralPromotionDailyRewards {
      date
      invites
      amount
    }
  }
`;

const fetchVipRebateBonus = gql`
  query fetchVipRebateBonus {
    fetchVipRebateBonus {
      id
      date
      withdrawPoint
      amount
      status
    }
  }
`;

const fetchLossBenefit = gql`
  query fetchLossBenefit {
    fetchLossBenefit {
      id
      date
      lossAmount
      amount
      status
    }
  }
`;

const fetchTeamCommission = gql`
  query fetchTeamCommission {
    fetchTeamCommission {
      id
      date
      amount
      status
    }
  }
`;

const receiveTreasurePromotionReward = gql`
  mutation receiveTreasurePromotionReward($activityId: Int!) {
    receiveTreasurePromotionReward(activityId: $activityId)
  }
`;

const receiveGeneralPromotionReward = gql`
  mutation receiveGeneralPromotionReward {
    receiveGeneralPromotionReward
  }
`;

const receiveVipRebateBonus = gql`
  mutation receiveVipRebateBonus($rewardId: Int!) {
    receiveVipRebateBonus(rewardId: $rewardId) {
      id
      status
    }
  }
`;

const receiveTeamCommission = gql`
  mutation receiveTeamCommission($rewardId: Int!) {
    receiveTeamCommission(rewardId: $rewardId) {
      id
      status
    }
  }
`;

const receiveLossBenefit = gql`
  mutation receiveLossBenefit($rewardId: Int!) {
    receiveLossBenefit(rewardId: $rewardId) {
      id
      status
    }
  }
`;

export const queries = {
  getUserInfo,
  refreshUserInfo,
  getTeamStats,
  listSpecialPromotionRewards,
  listTreasurePromotionRewardSummary,
  listGeneralPromotionDailyRewards,
  getTodayInviteData,
  fetchWithdrawParams,
  fetchVipRebateBonus,
  fetchTeamCommission,
  fetchLossBenefit,
};

export const mutations = {
  updateAvatar,
  updatePassword,
  updateFundPassword,
  updateBankcard,
  updatePixWallet,
  updateWallet,
  readSiteMessage,
  readAllSiteMessages,
  dailySignup,
  receiveVipBonus,
  receiveSpecialPromotionReward,
  receiveTreasurePromotionReward,
  receiveGeneralPromotionReward,
  receiveVipRebateBonus,
  receiveTeamCommission,
  receiveLossBenefit,
};
